<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <div class="title-container">
                    <h1 class="h1">Gestão de Equipamentos e Esterilizáveis</h1>
                </div>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" >
            <v-tab>Controle Equipamento</v-tab>
            <v-tab>Controle Esterilizável</v-tab>
            <v-tab>Cadastro</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <ControleEquipamento/>
            </v-tab-item>
            <v-tab-item>
                <ControleEsterelizavel/>
            </v-tab-item>
             <v-tab-item>
                <v-tabs v-model="subTab" class="mt-4">
                    <v-tab>Equipamentos</v-tab>
                    <v-tab>Esterilizável</v-tab>
                    <v-tab>Kit's</v-tab>
                    <v-tab>Equipamentos Dependentes</v-tab>
                    <v-tab>Fornecedores</v-tab>
                    <v-tab>Nots Fiscais</v-tab>
                    <v-tab>Esterilização</v-tab>
                </v-tabs>
                <v-tabs-items v-model="subTab">
                     <v-tab-item>
                        <Equipamentos/>
                    </v-tab-item>
                    <v-tab-item>
                        <Esterelizavel/>
                    </v-tab-item>
                    <v-tab-item>
                        <Kit/>
                    </v-tab-item>
                    <v-tab-item>
                        <Dependencia/>
                    </v-tab-item>
                    <v-tab-item>
                        <Fornecedores/>
                    </v-tab-item>
                    <v-tab-item>
                        <NotaFiscal/>
                    </v-tab-item>
                    <v-tab-item>
                        <Esterilizacao/>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import '../../assets/css/styles.css'

export default {
    name: 'GestaoEquipamento',
    components: {
        ControleEquipamento: () => import("./controleEquipamento/Controle.vue"),
        ControleEsterelizavel: () => import("./controleEsterelizavel/Index.vue"),
        Equipamentos: () => import("./equipamento/index.vue"),
        Esterelizavel: () => import('./esterelizavel/index.vue'),
        Kit: () => import('./kit/index.vue'),
        Dependencia: () => import('./equipamentoDependente/index.vue'),
        Fornecedores: () =>import('./fornecedores/index.vue'),
        NotaFiscal:() =>import('./notaFiscal/NotaFiscal.vue'),
        Esterilizacao:() =>import('./esterelizacao/Esterilização.vue'),
    },
    data: () => ({
        tab: 0,
        subTab:0,
    })
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

</style>